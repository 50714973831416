module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/jasperkamphuis/Work/gatsby-theme-recipes-master/packages/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/home/jasperkamphuis/Work/gatsby-theme-recipes-master/packages/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Classic Recipes","short_name":"Recipes","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/home/jasperkamphuis/Work/gatsby-theme-recipes-master/packages/gatsby-theme-recipes/src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5ba6f3df7db6e689317d05b8a24925c5"},
    },{
      plugin: require('../../gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"title":"Classic Recipes","shortTitle":"Recipes"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-DHZY8HQXKP","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"https://kokenmetjasper.nl/","enableWebVitalsTracking":true},
    }]
