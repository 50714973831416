import theme from "@marinda/gatsby-theme-recipes/src/gatsby-plugin-theme-ui";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    muted: "#BFD3C1",
    modes: {
      dark: {
        ...theme.colors.modes.dark,
        muted: "#004d4d"
      }
    }
  }
};
